import React from "react";
import { useLocation } from "react-router-dom";
import {
  SGridHeaderContainer,
  SHeaderTitle,
  SButtonsContainer,
  SCloseButton,
  SCloseIcon,
} from "./styles";
import {
  useCurrentPortfolio,
  useCurrentPortfolioId,
  usePortfolioEditor,
  useSideEditor,
} from "../../../../store";
import { PortfoliosButton } from "../../PortfoliosSelector/PortfoliosButton/PortfoliosButton";
import { useAssetColor } from "../../../../utils";

export const Header: React.FC = () => {
  const { selectedPortfolio } = useCurrentPortfolio();
  const [currentPortfolioId] = useCurrentPortfolioId();
  const { setSideEditorActive, setPortfolioBeingEdited } = useSideEditor();
  const location = useLocation();
  const { generateAssetColor } = useAssetColor();
  const { isExpanded, isModalOpen } = usePortfolioEditor();

  // TODO: if badge does not come back by the time 'fase3' ends, cleanup this
  //       code
  // const assetsQuantity =
  //   (selectedPortfolio && Object.keys(selectedPortfolio.assets).length) || 0;

  // const handleEditPortfolio = (): void => {
  //   navigate("../", { replace: true });
  // };

  const handleCloseButton = (): void => {
    setSideEditorActive(false);
    setPortfolioBeingEdited(null);
  };

  const detectBarColor = (): string =>
    generateAssetColor(Number(currentPortfolioId));

  return (
    <SGridHeaderContainer $isExpanded={isExpanded}>
      {/* <SBadgeContainer>
        <SBadge
          badgeContent={assetsQuantity}
          color="primary"
          overlap="circular"
          showZero
        >
          <DonutLargeIcon />
        </SBadge>
      </SBadgeContainer> */}
      <SHeaderTitle>
        {/* {selectedPortfolio?.name || t("portfolioEditorHeader")} */}
        <PortfoliosButton
          text={String(selectedPortfolio?.name)}
          barColor={detectBarColor()}
          id={currentPortfolioId ? String(currentPortfolioId) : "0"}
          chipMode
        />
      </SHeaderTitle>
      <SButtonsContainer>
        {location.pathname === "/analysis" && !isModalOpen ? (
          <SCloseButton onClick={handleCloseButton}>
            <SCloseIcon />
          </SCloseButton>
        ) : null}

        {/* <SLaunchIcon onClick={handleEditPortfolio} /> */}
      </SButtonsContainer>
    </SGridHeaderContainer>
  );
};
