import React from "react";
import { PortfolioEditor } from "../PortfolioEditor";
import { Footer } from "../Footer/Footer";
import { SDialog, SDialogContent } from "./styles";
import { usePortfolioEditor } from "../../../../store";

export const PortfolioEditorModal: React.FC = () => {
  const { isModalOpen, closeModal } = usePortfolioEditor();

  return (
    <SDialog open={isModalOpen} onClose={closeModal} fullScreen>
      <SDialogContent>
        <PortfolioEditor />
        {/* eslint-disable react/jsx-boolean-value */}
        <Footer onAnalysisClick={closeModal} isModal={true} />
      </SDialogContent>
    </SDialog>
  );
};
