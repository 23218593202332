import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {
  ErrorOutline,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { PortfolioPlaceHolder } from "../../components";
import {
  LocaleConfigsSelector,
  PortfolioEditor,
  QuantumBanner,
  SearchAsset,
} from "../../components/shared";
import {
  useCurrentPortfolio,
  usePortfolioEditor,
  useShowShareError,
} from "../../store";
import {
  SGridColumnLeft,
  SGridColumnRight,
  SGridContainer,
  SGridLocaleFloatingMenu,
  SGridOuterContainer,
  SGridQuantumBanner,
  SMessage,
  SSearchArea,
  STitle,
  SPopover,
  SPopoverMessageContainer,
  SPopoverMessage,
  SClosePopoverButton,
  SClosePopoverIcon,
  ToggleButton,
} from "./styles";

export const PortfolioComposer: React.FC = () => {
  const { t } = useTranslation();
  const [secondaryMessage, setSecondaryMessage] = useState(false);
  const { isExpanded, toggleExpandedPortfolio } = usePortfolioEditor();
  const { selectedPortfolio } = useCurrentPortfolio();
  const theme = useTheme();
  const { shareError, setShareError } = useShowShareError();
  const gridRef = useRef<HTMLDivElement>(null);
  const allowExpandedEditor =
    theme.portfolioEditor.allowExpandedOnComposerPage && selectedPortfolio;

  const handleClosePopover = (): void => {
    setShareError(false);
  };

  const isSinglePortfolioMode = theme.portfolios.singlePortfolioMode;

  const messageBuilder = (): string => {
    if (secondaryMessage) {
      return isSinglePortfolioMode
        ? t("composerSelectAssetMessage.singleMode.secondary")
        : t("composerSelectAssetMessage.secondary");
    }

    return isSinglePortfolioMode
      ? t("composerSelectAssetMessage.singleMode.primary")
      : t("composerSelectAssetMessage.primary");
  };

  const handleExpandedEditor = (): JSX.Element | null => {
    if (!theme.portfolioEditor.showToggleButton) {
      return null;
    }
    return (
      <ToggleButton $isExpanded={isExpanded} onClick={toggleExpandedPortfolio}>
        {isExpanded ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </ToggleButton>
    );
  };

  return (
    <SGridOuterContainer>
      <SGridContainer
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <SGridColumnLeft
          sx={{ position: "relative" }}
          $isExpanded={isExpanded}
          ref={gridRef}
        >
          <SPopover
            open={shareError}
            anchorEl={gridRef.current}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <ClickAwayListener onClickAway={handleClosePopover}>
              <SPopoverMessageContainer>
                <SClosePopoverButton onClick={handleClosePopover}>
                  <SClosePopoverIcon />
                </SClosePopoverButton>
                <SPopoverMessage>
                  <SPopoverMessage>
                    <ErrorOutline />
                    {t("share.failedLoadingSharedLink")}
                  </SPopoverMessage>
                </SPopoverMessage>
              </SPopoverMessageContainer>
            </ClickAwayListener>
          </SPopover>
          <STitle>{t("composerSelecAssetTitle")}</STitle>
          <SMessage>{messageBuilder()}</SMessage>
          <SSearchArea>
            <SearchAsset
              setSecondaryMessage={setSecondaryMessage}
              secondaryMessage={secondaryMessage}
            />
          </SSearchArea>
          {theme.localeSettings.showSettingsGearButton &&
            theme.localeSettings.showInsideLeftColumn && (
              <SGridLocaleFloatingMenu>
                <LocaleConfigsSelector />
              </SGridLocaleFloatingMenu>
            )}
        </SGridColumnLeft>
        <SGridColumnRight $isExpanded={isExpanded}>
          {allowExpandedEditor && handleExpandedEditor()}
          {selectedPortfolio === null ? (
            <PortfolioPlaceHolder />
          ) : (
            <PortfolioEditor />
          )}
        </SGridColumnRight>
      </SGridContainer>
      <SGridQuantumBanner>
        <QuantumBanner />
      </SGridQuantumBanner>
    </SGridOuterContainer>
  );
};
