import styled from "styled-components";
import { Grid } from "@mui/material";

export const SGridChartContainer = styled(Grid)<{
  $isExpanded: boolean;
  $isHomePage: boolean;
}>`
  && {
    .chart {
      width: 100% !important;
      height: 100% !important;
    }
    justify-content: center;
    align-items: center;
    width: ${({ $isExpanded }) => ($isExpanded ? "100%" : "45%")};
    height: ${({ $isExpanded }) => ($isExpanded ? "180px" : "100%")};

    @media (max-height: 700px) {
      height: ${({ $isExpanded, theme }) =>
        $isExpanded && theme.portfolioEditor.allowExpandedOnComposerPage
          ? "140px"
          : "inherit"};
    }
  }
`;
