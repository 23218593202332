import styled from "styled-components";
import { Grid, Typography, Badge, IconButton } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import CloseIcon from "@mui/icons-material/Close";

export const SGridHeaderContainer = styled(Grid)<{ $isExpanded: boolean }>`
  && {
    color: ${({ theme }) => theme.colors.textGray};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: row;
    width: ${({ $isExpanded }) => ($isExpanded ? "" : "100%")};
    margin-top: ${({ $isExpanded }) => ($isExpanded ? "" : "16px")};
    margin-bottom: ${({ $isExpanded }) => ($isExpanded ? "" : "16px")};
  }
`;

export const SHeaderTitle = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textDefault};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-left: 32px;
    padding-right: 15px;

    @media (max-height: 700px) {
      padding-left: 23px;
    }
  }
`;

export const SBadgeContainer = styled(Grid)`
  && {
    flex: 1;
  }
`;

export const SBadge = styled(Badge)`
  && {
    .MuiBadge-badge {
      font-size: 12px;
      font-weight: 400;
      height: 16px;
      width: 16px;
      padding: 0;
      padding-left: 1px;
      padding-bottom: 1px;
      min-width: 16px;
    }

    .MuiBadge-colorPrimary {
      background-color: ${({ theme }) => theme.colors.iconCyan};
    }

    color: ${({ theme }) => theme.colors.iconCyan};
    margin-left: 32px;
  }
`;

export const SButtonsContainer = styled(Grid)`
  && {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
`;

export const SLaunchIcon = styled(LaunchIcon)`
  && {
    color: ${({ theme }) => theme.colors.textDefault};
    margin-right: 32px;
    cursor: pointer;
  }
`;

export const SCloseButton = styled(IconButton)`
  && {
    padding: 0;
    margin-right: 27px;
  }
`;

export const SCloseIcon = styled(CloseIcon)`
  && {
    margin: 10px;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.textDefault};
  }
`;
