import {
  BenchmarkAssetType,
  DateRangeType,
  ReduxStateType,
  CompetenceType,
  UserDataType,
  TabsType,
} from ".";
import { SearchResultsType } from "../components/shared";
import { superCarteiraFeatures } from "../pages";

export type ReduxActions =
  | {
      type: "SET_STORE_FROM_SHARED_LINK";
      payload: ReduxStateType;
    }
  | { type: "SHOW_SPINNER"; payload: boolean }
  | { type: "SET_LANGUAGE"; payload: string }
  | { type: "SET_CURRENCY"; payload: string }
  | { type: "SET_PORTFOLIO_SELECTED_ID"; payload: number | null }
  | {
      type: "ADD_OR_UPDATE_ASSET_TO_PORTFOLIO";
      payload: SearchResultsType;
      portfolioId: number;
      percentage: number | null;
      amount: number | null;
      rule: RuleType;
      isLocked: boolean;
    }
  | {
      type: "REMOVE_ASSET_FROM_PORTFOLIO";
      payload: string;
      portfolioId: number;
    }
  | {
      type: "UPDATE_ASSET_LOCK_IN_PORTFOLIO";
      payload: string;
      portfolioId: number;
      locked: boolean;
    }
  | {
      type: "RENAME_PORTFOLIO";
      payload: string;
      portfolioId: number;
    }
  | {
      type: "UPDATE_PORTFOLIO_TOTAL";
      payload: number;
      portfolioId: number;
    }
  | {
      type: "SET_PERIOD";
      payload: ReduxStateType["period"];
    }
  | {
      type: "ADD_PORTFOLIO";
      payload: "";
    }
  | {
      type: "ADD_BENCHMARK";
      payload: BenchmarkAssetType;
    }
  | {
      type: "REMOVE_BENCHMARK";
      payload: string;
    }
  | {
      type: "BALANCE_PORTFOLIO";
      portfolioId: number;
    }
  | {
      type: "ADD_OR_UPDATE_PORTFOLIO_REMOTE_ID";
      portfolioId: number;
      remotePortfolioId: string;
    }
  | {
      type: "SET_APPLICATION_AMOUNT";
      payload: number;
    }
  | {
      type: "CLONE_PORTFOLIO";
      cloneFromId: number;
      remoteId: string;
    }
  | {
      type: "SET_SHOW_SIDE_EDITOR";
      payload: boolean;
    }
  | {
      type: "UPDATE_PORTFOLIO_BEING_EDITED";
      payload: number | null;
    }
  | {
      type: "SET_SHOW_ASSETS";
      payload: boolean;
    }
  | {
      type: "REMOVE_PORTFOLIO";
      payload: number;
    }
  | {
      type: "SET_CUSTOM_DATE_RANGE";
      payload: DateRangeType;
    }
  | {
      type: "SET_FETCHED_DATE_RANGE";
      payload: DateRangeType;
    }
  | {
      type: "SET_WINDOW";
      payload: number;
    }
  | {
      type: "SET_CONSOLIDATED";
      payload: ReduxStateType["consolidated"];
    }
  | {
      type: "SET_COMPETENCE";
      payload: ReduxStateType["competence"];
    }
  | {
      type: "SET_SHARE_ERROR";
      payload: boolean;
    }
  | {
      type: "UNLOCK_ALL_PORTFOLIO_ASSETS";
      payload: number;
    }
  | {
      type: "SET_LOADING_FROM_LINK";
      payload: boolean;
      task: keyof ReduxStateType["sharedLinkTasks"];
    }
  | {
      type: "ADD_CUSTOM_BENCHMARK_ASSET";
      payload: SearchResultsType;
    }
  | {
      type: "SET_AVAILABLE_COMPETENCES";
      payload: CompetenceType;
    }
  | {
      type: "SET_SHOW_CUSTOM_BENCHMARKS";
      payload: boolean;
    }
  | {
      type: "SET_UPDATE_USER_AUTH";
      payload: boolean;
    }
  | {
      type: "UPDATE_USER_DATA";
      payload: UserDataType;
    }
  | {
      type: "UPDATE_SUPERCARTEIRA_FEATURE";
      payload: superCarteiraFeatures;
    }
  | {
      type: "SET_SHOW_IMPORTED_NOTIFIER";
      payload: boolean;
    }
  | {
      type: "SET_BLANK_PORTFOLIO";
    }
  | {
      type: "UPDATE_SELECTED_TAB";
      payload: TabsType;
    }
  | {
      type: "SET_PORTFOLIO_EDITOR_EXPANDED";
      payload: boolean;
    }
  | {
      type: "SET_PORTFOLIO_EDITOR_MODAL_OPEN";
      payload: boolean;
    };

export type RuleType =
  | "RULE_ADD_ASSET_INVESTMENT_VALUE"
  | "RULE_EDIT_ASSET_PERCENTAGE";

export const setStoreSharedLink = (state: ReduxStateType): ReduxActions => {
  return {
    type: "SET_STORE_FROM_SHARED_LINK",
    payload: state,
  };
};

export const setSpinner = (show: boolean): ReduxActions => {
  return {
    type: "SHOW_SPINNER",
    payload: show,
  };
};

export const setLanguage = (language: string): ReduxActions => {
  return {
    type: "SET_LANGUAGE",
    payload: language,
  };
};

export const setCurrency = (currency: string): ReduxActions => {
  return {
    type: "SET_CURRENCY",
    payload: currency,
  };
};

export const setCurrentPortfolio = (
  portfolioNumber: number | null
): ReduxActions => {
  return {
    type: "SET_PORTFOLIO_SELECTED_ID",
    payload: portfolioNumber,
  };
};

export const addOrUpdateAssetsToPortfolio = (
  portfolioAssets: SearchResultsType,
  currentPortfolioId: number,
  percentage: number | null,
  amount: number | null,
  rule: RuleType,
  isLocked: boolean
): ReduxActions => {
  return {
    type: "ADD_OR_UPDATE_ASSET_TO_PORTFOLIO",
    payload: portfolioAssets,
    portfolioId: currentPortfolioId,
    percentage,
    amount,
    rule,
    isLocked,
  };
};

export const removeAssetFromPortfolio = (
  assetId: string,
  currentPortfolioId: number
): ReduxActions => {
  return {
    type: "REMOVE_ASSET_FROM_PORTFOLIO",
    payload: assetId,
    portfolioId: currentPortfolioId,
  };
};

export const updateAssetLockInPortfolio = (
  assetId: string,
  currentPortfolioId: number,
  locked: boolean
): ReduxActions => {
  return {
    type: "UPDATE_ASSET_LOCK_IN_PORTFOLIO",
    payload: assetId,
    portfolioId: currentPortfolioId,
    locked,
  };
};

export const unlockAllPortfolioAssets = (
  currentPortfolioId: number
): ReduxActions => {
  return {
    type: "UNLOCK_ALL_PORTFOLIO_ASSETS",
    payload: currentPortfolioId,
  };
};

export const renameCurrentPortfolio = (
  newName: string,
  currentPortfolioId: number
): ReduxActions => {
  return {
    type: "RENAME_PORTFOLIO",
    payload: newName,
    portfolioId: currentPortfolioId,
  };
};

export const updateTotalAmount = (
  totalAmount: number,
  currentPortfolioId: number
): ReduxActions => {
  return {
    type: "UPDATE_PORTFOLIO_TOTAL",
    payload: totalAmount,
    portfolioId: currentPortfolioId,
  };
};

export const setPeriod = (period: ReduxStateType["period"]): ReduxActions => {
  return {
    type: "SET_PERIOD",
    payload: period,
  };
};

export const addNewPortfolio = (): ReduxActions => {
  return {
    type: "ADD_PORTFOLIO",
    payload: "",
  };
};

export const clonePortfolio = (
  portfolioNumber: number,
  remotePortfolioId: string
): ReduxActions => {
  return {
    type: "CLONE_PORTFOLIO",
    cloneFromId: portfolioNumber,
    remoteId: remotePortfolioId,
  };
};

export const addBenchmark = (
  benchmarkItem: BenchmarkAssetType
): ReduxActions => {
  return {
    type: "ADD_BENCHMARK",
    payload: benchmarkItem,
  };
};

export const addCustomBenchmarkAsset = (
  customAssetItem: SearchResultsType
): ReduxActions => {
  return {
    type: "ADD_CUSTOM_BENCHMARK_ASSET",
    payload: customAssetItem,
  };
};

export const removeBenchmark = (benchmarkId: string): ReduxActions => {
  return {
    type: "REMOVE_BENCHMARK",
    payload: benchmarkId,
  };
};

export const balancePortfolio = (currentPortfolioId: number): ReduxActions => {
  return {
    type: "BALANCE_PORTFOLIO",
    portfolioId: currentPortfolioId,
  };
};

export const addOrUpdateRemotePortfolioId =
  (currentPortfolioId: number, remotePortfolioId: string): Promise<void> =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  async (dispatch: AppDispatchType) => {
    dispatch({
      type: "ADD_OR_UPDATE_PORTFOLIO_REMOTE_ID",
      portfolioId: currentPortfolioId,
      remotePortfolioId,
    });
  };

export const setApplicationAmount = (amount: number): ReduxActions => {
  return {
    type: "SET_APPLICATION_AMOUNT",
    payload: amount,
  };
};

export const showSideEditor = (show: boolean): ReduxActions => {
  return {
    type: "SET_SHOW_SIDE_EDITOR",
    payload: show,
  };
};

export const updatePortfolioBeingEdited = (
  portfolioId: number | null
): ReduxActions => {
  return {
    type: "UPDATE_PORTFOLIO_BEING_EDITED",
    payload: portfolioId,
  };
};

export const showAssets = (show: boolean): ReduxActions => {
  return {
    type: "SET_SHOW_ASSETS",
    payload: show,
  };
};

export const removePortfolio = (portfolioId: number): ReduxActions => {
  return {
    type: "REMOVE_PORTFOLIO",
    payload: portfolioId,
  };
};

export const updateCustomDateRange = (
  dateRange: DateRangeType
): ReduxActions => {
  return {
    type: "SET_CUSTOM_DATE_RANGE",
    payload: dateRange,
  };
};

export const updateFetchedDateRange = (
  dateRange: DateRangeType
): ReduxActions => {
  return {
    type: "SET_FETCHED_DATE_RANGE",
    payload: dateRange,
  };
};

export const updateWindow = (windowValue: number): ReduxActions => {
  return { type: "SET_WINDOW", payload: windowValue };
};

export const setConsolidated = (
  consolidatedBy: ReduxStateType["consolidated"]
): ReduxActions => {
  return {
    type: "SET_CONSOLIDATED",
    payload: consolidatedBy,
  };
};

export const setCompetence = (
  selectedCompetence: ReduxStateType["competence"]
): ReduxActions => {
  return {
    type: "SET_COMPETENCE",
    payload: selectedCompetence,
  };
};

export const showShareError = (showError: boolean): ReduxActions => {
  return {
    type: "SET_SHARE_ERROR",
    payload: showError,
  };
};

export const updateLoadingFromSharedLink = (
  loadingState: boolean,
  task: keyof ReduxStateType["sharedLinkTasks"]
): ReduxActions => {
  return {
    type: "SET_LOADING_FROM_LINK",
    payload: loadingState,
    task,
  };
};

export const updateAvailableCompetences = (
  competences: CompetenceType
): ReduxActions => {
  return {
    type: "SET_AVAILABLE_COMPETENCES",
    payload: competences,
  };
};

export const updateShowAllSearchableCustomBenchmarks = (
  show: boolean
): ReduxActions => {
  return {
    type: "SET_SHOW_CUSTOM_BENCHMARKS",
    payload: show,
  };
};

export const updateIsUserAuthenticated = (auth: boolean): ReduxActions => {
  return {
    type: "SET_UPDATE_USER_AUTH",
    payload: auth,
  };
};

export const updateUserData = (data: UserDataType): ReduxActions => {
  return {
    type: "UPDATE_USER_DATA",
    payload: data,
  };
};

export const updateSuperCarteiraFeature = (
  data: superCarteiraFeatures
): ReduxActions => {
  return {
    type: "UPDATE_SUPERCARTEIRA_FEATURE",
    payload: data,
  };
};

export const updateShowImportedNotifier = (show: boolean): ReduxActions => {
  return {
    type: "SET_SHOW_IMPORTED_NOTIFIER",
    payload: show,
  };
};

export const setBlankPortfolio = (): ReduxActions => {
  return {
    type: "SET_BLANK_PORTFOLIO",
  };
};

export const updateSelectedTab = (data: TabsType): ReduxActions => {
  return {
    type: "UPDATE_SELECTED_TAB",
    payload: data,
  };
};

export const setPortfolioEditorExpanded = (expanded: boolean): ReduxActions => {
  return {
    type: "SET_PORTFOLIO_EDITOR_EXPANDED",
    payload: expanded,
  };
};

export const setPortfolioEditorModalOpen = (isOpen: boolean): ReduxActions => {
  return {
    type: "SET_PORTFOLIO_EDITOR_MODAL_OPEN",
    payload: isOpen,
  };
};
