import React from "react";
import { useNavigate, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import {
  SGridCard,
  SGridContainer,
  SGridRow2,
  SGridRow3,
  LeftInputColumn,
  RightInputColumn,
  SGridRow4,
  SAddAssets,
  SAddIcon,
  SRemoveButton,
  SRemove,
  SGridRow,
  SCloseButton,
} from "./styles";
import { Header } from "./Header/Header";
import { Footer } from "./Footer/Footer";
import { PortfolioSummaryDonutChart } from "./PortfolioSummaryGraph/PortfolioSummaryGraph";
import { PortfolioName } from "./PortfolioName/PortfolioName";
import { PortfolioTotalAmount } from "./PortfolioTotalAmount/PortfolioTotalAmount";
import { PortfolioEditorAssetsList } from "./PortfolioEditorAssetsList/PortfolioEditorAssetsList";
import { PortfolioSummaryDonutChartLegend } from "./PortfolioSummaryGraphLegend/PortfolioSummaryGraphLegend";
import { BalancingButton } from "./BalancingButton/BalancingButton";
import {
  useCurrentPortfolio,
  usePortfolioEditor,
  useSideEditor,
  useAllPortfolios,
  useBenchmarks,
  useCurrentPortfolioId,
  useCustomDateRange,
  useInitialApplication,
  usePeriod,
  useWallet,
  useWindowValue,
  useUserData,
  useSuperCarteiraFeature,
  UserDataType,
} from "../../../store";
import { useSaveUserData } from "../../../api";
import {
  SButton,
  SContentSaveIcon,
  SLinearProgress,
} from "../ShareButtons/styles";

export const PortfolioEditor: React.FC = () => {
  const { removeCurrentPortfolio, isLastPortfolio } = useCurrentPortfolio();
  const { setPortfolioBeingEdited } = useSideEditor();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  const { isExpanded, closeModal, isModalOpen } = usePortfolioEditor();
  const { allPortfolios } = useAllPortfolios();
  const { selectedBenchmarks } = useBenchmarks();
  const { windowValue } = useWindowValue();
  const { selectedPeriod } = usePeriod();
  const { selectedCustomDateRange } = useCustomDateRange();
  const { initialApplication } = useInitialApplication();
  const [currentPortfolioId] = useCurrentPortfolioId();
  const { selectedCompetence, selectedConsolidated } = useWallet();
  const { userData } = useUserData();
  const { superCarteiraFeature } = useSuperCarteiraFeature();
  const { fetch: saveUserData, loading: isSavingUserData } = useSaveUserData();

  const isHomePage = location.pathname === "/";
  const isAnalysisPage = location.pathname === "/analysis";
  const showCloseButton =
    isAnalysisPage && theme.portfolioEditor.allowModalEditingOnAnalysisPage;

  const handleAddAsset = (): void => {
    navigate("../", { replace: true });
  };

  const showBalacingButton = theme?.portfolios?.balancingButton.show;

  const handleRemovePortfolio = (): void => {
    if (isLastPortfolio) {
      navigate("../");
    }
    setPortfolioBeingEdited(null);
    removeCurrentPortfolio();
  };

  const handleSavePortfolio = (): void => {
    const portfolioIdentifiers = Object.keys(allPortfolios)
      .map((portfolioKey) => allPortfolios[Number(portfolioKey)].identifier)
      .filter((identifier) => identifier !== undefined);

    const benchmarksIdentifiers = Object.keys(selectedBenchmarks)
      .map((benchmarkKey) => [
        selectedBenchmarks[benchmarkKey].asset.assetType,
        selectedBenchmarks[benchmarkKey].asset.identifier,
      ])
      .flat();

    const selectedCustomDateArray = [
      selectedCustomDateRange.initialDate,
      selectedCustomDateRange.finalDate,
    ];

    const newData = {
      id: portfolioIdentifiers[0],
      benchmark: benchmarksIdentifiers,
      period: selectedPeriod,
      windowValue,
      initialApplication,
      currentPortfolioId,
      selectedCustomDateRange:
        selectedPeriod === "DATA_PERSONALIZADA"
          ? selectedCustomDateArray
          : null,
      consolidated: selectedConsolidated,
      competence: selectedCompetence,
    } as unknown as UserDataType["portfolios"]["supercarteira"];

    saveUserData(userData.email, newData, superCarteiraFeature);
  };

  const handleCloseModal = (): void => {
    closeModal();
  };

  return (
    <>
      <SGridContainer $isExpanded={isExpanded}>
        <SGridCard $isExpanded={isExpanded}>
          {showCloseButton && (
            <SCloseButton onClick={handleCloseModal}>×</SCloseButton>
          )}
          {theme.portfolioEditor.allowModalEditingOnAnalysisPage &&
            location.pathname === "/analysis" && (
              <SButton
                onClick={handleSavePortfolio}
                disabled={isSavingUserData}
              >
                <span style={{ height: "24px" }}>
                  <SContentSaveIcon />
                </span>
                <span style={{ width: "140px" }}>
                  {isSavingUserData ? (
                    <SLinearProgress variant="indeterminate" color="inherit" />
                  ) : (
                    t("share.save")
                  )}
                </span>
              </SButton>
            )}
          <Header />
          <SGridRow2 $isExpanded={isExpanded}>
            <PortfolioSummaryDonutChart />
            <PortfolioSummaryDonutChartLegend />
          </SGridRow2>
          <SGridRow3>
            <SGridRow $isExpanded={isExpanded}>
              <LeftInputColumn $isExpanded={isExpanded}>
                <PortfolioName isHomePage={isHomePage} />
              </LeftInputColumn>
              <RightInputColumn $isExpanded={isExpanded}>
                <PortfolioTotalAmount />
                {showBalacingButton ? <BalancingButton /> : null}
              </RightInputColumn>
            </SGridRow>
          </SGridRow3>
          {location.pathname === "/analysis" && !isModalOpen ? (
            <SGridRow4>
              <SRemoveButton onClick={handleRemovePortfolio}>
                <SRemove />
              </SRemoveButton>
              <SAddAssets onClick={handleAddAsset}>
                <SAddIcon />
                {t("portfolioEditorAddAsset")}
              </SAddAssets>
            </SGridRow4>
          ) : null}
        </SGridCard>
        <PortfolioEditorAssetsList />
      </SGridContainer>
      <Footer />
    </>
  );
};
