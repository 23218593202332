import React from "react";
import Big from "big.js";
import Highcharts from "highcharts";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import {
  Chart,
  HighchartsChart,
  HighchartsProvider,
  PieSeries,
  Tooltip,
  Title,
} from "react-jsx-highcharts";
import { useLocation } from "react-router";
import { colorFromAssetType } from "../..";
import {
  calculateAssetsTotalAmount,
  calculateAssetsTotalPercentage,
} from "../../../../utils";
import { SGridChartContainer } from "./styles";
import {
  useCurrency,
  useCurrentPortfolio,
  usePortfolioEditor,
} from "../../../../store";

export const PortfolioSummaryDonutChart: React.FC = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { selectedPortfolio } = useCurrentPortfolio();
  const { currency } = useCurrency();
  const { isExpanded } = usePortfolioEditor();
  const location = useLocation();

  const totalAssetsPercentage = calculateAssetsTotalPercentage(
    selectedPortfolio && selectedPortfolio.assets
  );

  const isHomePage = location.pathname === "/";

  const formattedAssets = selectedPortfolio
    ? [
        {
          name: t("summaryChart.leftover"),
          y: Big(100).minus(totalAssetsPercentage).round(2).toNumber(),
          color: theme.colors.leftover,
        },
        ...Object.keys(selectedPortfolio.assets).map((key) => ({
          name:
            selectedPortfolio.assets[key].asset.label ||
            t("summaryChart.unlabeled"),
          y: Big(selectedPortfolio.assets[key].percentage || 0)
            .round(2)
            .toNumber(),
          color:
            totalAssetsPercentage > 100
              ? theme.colors.alertRed
              : colorFromAssetType(
                  selectedPortfolio.assets[key].asset.portfolioCategory,
                  theme
                ),
        })),
      ]
    : [];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const currencySymbol = t(`currency.${currency}.symbol`);

  return (
    <SGridChartContainer $isExpanded={isExpanded} $isHomePage={isHomePage}>
      <HighchartsProvider Highcharts={Highcharts}>
        <HighchartsChart
          exporting={{ enabled: false }}
          plotOptions={{
            pie: {
              size: "120%",
              innerSize: "75%",
              borderWidth: 1,
              borderColor: theme.colors.bgPrimary,
              center: ["50%", "50%"],
            },
          }}
        >
          <Chart type="pie" />
          <Title
            align="center"
            verticalAlign="middle"
            useHTML
            style={{
              color: theme.colors.textDefault,
              fontFamily: theme.fonts.primary,
            }}
          >
            {`
            <span style="display: flex; flex-direction: column;">
              <span style="font-weight: 600; font-size: 11px; align-self: center;">
              ${currencySymbol} ${new Intl.NumberFormat(i18n.language, {
              style: "decimal",
              minimumFractionDigits: 2,
            }).format(
              Big(
                (selectedPortfolio &&
                  calculateAssetsTotalAmount(selectedPortfolio.assets)) ||
                  0
              )
                .round(2)
                .toNumber()
            )}
              </span>
              <span style="font-weight: 300; font-size: 11px; align-self: center;">
              ${t("portfolioEditorInvestedTotal")}
              </span>
            </span>`}
          </Title>
          <Tooltip
            outside
            useHTML
            headerFormat={`
            <div style="display: flex;
                        flex-direction: row;
                        ">
            <div style="height: 16px;
                       width: 4px; min-width: 4px;
                       background-color: {point.color};
                       margin-right: 10px;
                       ">
            </div>
            <span>{point.key}</span>
            </div>`}
            pointFormat={`<div style="font-weight: 600;">{point.y}%</div>`}
            style={{
              zIndex: 9990,
            }}
          />
          <PieSeries data={formattedAssets} dataLabels={{ enabled: false }} />
        </HighchartsChart>
      </HighchartsProvider>
    </SGridChartContainer>
  );
};
