import styled from "styled-components";
import { Button } from "@mui/material";

interface GridProps {
  $isExpanded: boolean;
  $isSupercarteira: boolean;
  $isModal: boolean;
}

export const SGrid = styled.div<GridProps>`
  && {
    margin-top: ${({ $isModal }) => ($isModal ? "-44px" : "-54px")};
    display: flex;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.addButtonPrimary};
    width: ${({ $isExpanded }) => ($isExpanded ? "100%" : "50%")};
    min-height: 56px;
    z-index: 1000;

    ${({ $isModal, $isSupercarteira }) => {
      if ($isModal) return "";

      return `
        position: absolute;
        max-width: ${!$isSupercarteira ? "405px" : "48.8%"};
      `;
    }}
  }
`;

export const SButton = styled(Button)`
  && {
    display: flex;
    justify-content: center;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-transform: none;
    color: ${({ theme }) => theme.colors.addButtonText};
    width: 50%;
  }
`;
