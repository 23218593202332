import React, { useCallback } from "react";
import { useTheme } from "styled-components";
import {
  useClonePortfolio,
  useSideEditor,
  usePortfolioEditor,
} from "../../../../store";
import { SBar, SButton, SEditIcon, STitle } from "./styles";

interface PortfoliosButtonPropsType {
  barColor: string;
  text: string;
  id: string;
  cloneMode?: boolean;
  cloneModeCloseMenu?: () => void | string;
  chipMode?: boolean;
}

export const PortfoliosButton: React.FC<PortfoliosButtonPropsType> = ({
  text,
  barColor,
  id,
  cloneMode,
  cloneModeCloseMenu,
  chipMode,
}) => {
  PortfoliosButton.defaultProps = {
    cloneMode: false,
    cloneModeCloseMenu: () => "",
    chipMode: false,
  };

  const { clonePortfolioFromId } = useClonePortfolio();
  const { portfolioBeingEdited, setPortfolioBeingEdited } = useSideEditor();
  const { openModal } = usePortfolioEditor();
  const theme = useTheme();

  const { allowModalEditingOnAnalysisPage } = theme.portfolioEditor;

  const handleSelect = useCallback((): void => {
    if (!cloneMode) {
      if (portfolioBeingEdited === Number(id)) {
        if (allowModalEditingOnAnalysisPage) {
          openModal();
          return;
        }
        setPortfolioBeingEdited(null);
      } else {
        if (allowModalEditingOnAnalysisPage) {
          setPortfolioBeingEdited(Number(id));
          openModal();
        }
        setPortfolioBeingEdited(Number(id));
      }
    }

    if (cloneMode) {
      clonePortfolioFromId(Number(id));
      if (cloneModeCloseMenu) cloneModeCloseMenu();
    }
  }, [
    cloneMode,
    cloneModeCloseMenu,
    clonePortfolioFromId,
    id,
    portfolioBeingEdited,
    setPortfolioBeingEdited,
    openModal,
    allowModalEditingOnAnalysisPage,
  ]);

  return (
    <SButton
      endIcon={
        !chipMode &&
        !cloneMode &&
        portfolioBeingEdited !== Number(id) && <SEditIcon />
      }
      selected={
        (!cloneMode && portfolioBeingEdited === Number(id)) || Boolean(chipMode)
      }
      onClick={handleSelect}
    >
      <SBar barColor={barColor} />
      <STitle $chipMode={chipMode || false}>{text}</STitle>
    </SButton>
  );
};
