import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { useLocation } from "react-router-dom";
import {
  AssetsType,
  useCurrentPortfolio,
  useGlobalConfigs,
  usePortfolioEditor,
} from "../../../../store";
import { translateCategory } from "../../../../utils";
import { colorFromAssetType } from "../../SearchAsset/SearchedAssetItem/SearchedAssetItem";
import { PortfolioEditorAssetItem } from "../PortfolioEditorAssetItem/PortfolioEditorAssetItem";
import {
  SAssetsHeader,
  SCategorySeparatorLine,
  SGridContainer,
  SGridRow1,
  SGridRow2,
  SCloseButton,
} from "./styles";

export const PortfolioEditorAssetsList: React.FC = () => {
  const { t } = useTranslation();
  const { selectedPortfolio } = useCurrentPortfolio();
  const { orderedPortfolioCategories } = useGlobalConfigs();
  const { isExpanded, closeModal } = usePortfolioEditor();
  const theme = useTheme();
  const location = useLocation();

  const isAnalysisPage = location.pathname === "/analysis";
  const showCloseButton =
    isAnalysisPage && theme.portfolioEditor.allowModalEditingOnAnalysisPage;

  const assetsLength =
    selectedPortfolio && Object.keys(selectedPortfolio.assets).length;
  const isPlural = assetsLength !== 1;

  const isNotEmptyCategory = (
    category: string,
    assetsObj: AssetsType
  ): boolean =>
    Object.keys(assetsObj).filter(
      (assetKey) => assetsObj[assetKey].asset.portfolioCategory === category
    ).length > 0;

  const handleCloseModal = (): void => {
    closeModal();
  };

  return (
    <SGridContainer>
      <SGridRow1>
        <SAssetsHeader>
          {assetsLength}{" "}
          {isPlural ? t("editorList.asset_plural") : t("editorList.asset")}{" "}
          {isPlural
            ? t("editorList.selected_plural")
            : t("editorList.selected")}
        </SAssetsHeader>
        {showCloseButton && (
          <SCloseButton onClick={handleCloseModal}>×</SCloseButton>
        )}
      </SGridRow1>
      <SGridRow2 $isExpanded={isExpanded}>
        {selectedPortfolio &&
          orderedPortfolioCategories.map((portfolioCategory) => (
            <React.Fragment key={`${portfolioCategory}${Math.random()}`}>
              {theme.portfolioSummary.noCardIcons &&
              isNotEmptyCategory(
                portfolioCategory,
                selectedPortfolio.assets
              ) ? (
                <SCategorySeparatorLine
                  key={portfolioCategory}
                  $barColor={colorFromAssetType(portfolioCategory, theme)}
                >
                  {translateCategory(portfolioCategory)}
                </SCategorySeparatorLine>
              ) : null}

              {Object.keys(selectedPortfolio.assets).map((assetKey) =>
                selectedPortfolio.assets[assetKey].asset.portfolioCategory ===
                portfolioCategory ? (
                  <PortfolioEditorAssetItem
                    key={assetKey}
                    assetKey={assetKey}
                    asset={selectedPortfolio.assets[assetKey]}
                  />
                ) : null
              )}
            </React.Fragment>
          ))}
      </SGridRow2>
    </SGridContainer>
  );
};
