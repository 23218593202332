import styled from "styled-components";
import { Dialog, DialogContent, Button } from "@mui/material";

export const SDialog = styled(Dialog)`
  && {
    position: fixed;
    top: 84px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;

    .MuiDialog-paper {
      width: 100%;
      height: calc(100vh - 84px);
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      max-width: none;
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: 768px) {
    && {
      top: 84px;
      .MuiDialog-paper {
        height: calc(100vh - 84px);
      }
    }

    && {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 0.9em;
      }

      p,
      span,
      div,
      button,
      input,
      label {
        font-size: 0.85em;
      }

      .MuiTypography-root {
        font-size: 0.85em;
      }

      .MuiButton-root {
        font-size: 0.85em;
      }

      .MuiInputBase-root {
        font-size: 0.85em;
      }
    }
  }
`;

export const SDialogContent = styled(DialogContent)`
  && {
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    margin: 0;
    flex: 1;
    min-height: 0;
  }

  @media (max-width: 768px) {
    && {
      padding: 0;
      margin: 0;
    }
  }
`;

export const SHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.textGray};

  @media (max-width: 768px) {
    padding: 12px 16px;
  }
`;

export const SFooterWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.textGray};
  margin-top: auto;
  flex-shrink: 0;

  @media (max-width: 768px) {
    padding: 8px 16px;
  }
`;

export const SFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.textGray};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  flex-shrink: 0;

  @media (max-width: 768px) {
    padding: 12px;
  }
`;

export const SButton = styled(Button)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.textWhite};
    background-color: ${({ theme }) => theme.colors.textDark};
    text-transform: none;
    white-space: nowrap;
    height: 36px;
    padding: 0 24px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.addButtonSecondary};
    }

    @media (max-width: 768px) {
      font-size: 11px;
      height: 32px;
      padding: 0 16px;
    }
  }
`;
