import styled from "styled-components";
import { Input, Typography, Grid } from "@mui/material";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";

export const SInputContainer = styled(Grid)`
  width: 100%;
`;

export const SInputTitle = styled(Typography)<{ $isHomePage?: boolean }>`
  && {
    color: inherit;
    font-family: inherit;
    font-style: inherit;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 4px;

    @media (max-width: 1420px), (max-height: 700px) {
      font-size: ${({ $isHomePage, theme }) =>
        $isHomePage && theme.portfolioEditor.allowExpandedOnComposerPage
          ? "11px"
          : "12px"};
    }
  }
`;

export const SInputField = styled(Input)<{ $isHomePage?: boolean }>`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.textDefault || "black"};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    margin-bottom: 4px;
    padding: 0;
    padding-left: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 6px;
    width: 139px
      ${({ theme }) =>
        theme.portfolios.balancingButton.show ? "139px" : "100%"};
    height: 40px;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.bgGray || "gray"};

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px
        ${({ theme }) => theme.colors.bgGray || "gray"} inset;
    }

    input {
      padding-top: 4px;
    }

    @media (max-height: 700px) {
      font-size: ${({ theme }) => {
        return theme.portfolioEditor.allowExpandedOnComposerPage &&
          theme.portfolioEditor.allowModalEditingOnAnalysisPage
          ? "11px"
          : "14px";
      }};
      height: ${({ theme }) => {
        return theme.portfolioEditor.allowExpandedOnComposerPage &&
          theme.portfolioEditor.allowModalEditingOnAnalysisPage
          ? "32px"
          : "40px";
      }};
    }
  }
`;

export const SInputDescription = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textGray};
    font-family: inherit;
    font-style: inherit;
    font-size: 12px;
    font-weight: 400;
  }
`;

export const SEditIcon = styled(DriveFileRenameOutlineOutlinedIcon)`
  color: ${({ theme }) => theme.colors.textDark};
  padding-right: 3px;
`;
