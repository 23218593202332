import { Typography, List, Grid } from "@mui/material";
import styled from "styled-components";

export const SEmptyBox = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textGray};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 151px;
    border: 1px dashed ${({ theme }) => theme.colors.textGray};
  }
`;

export const SGridContainer = styled(Grid)`
  && {
    padding-top: 12px;
    padding-left: 32px;
    padding-right: 27px;
    padding-bottom: 8px;
    flex: 6;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d6d3d3;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-button:start:increment {
      height: 3%;
      display: block;
      background: transparent;
    }
  }
`;

export const SGridRow1 = styled(Grid)`
  && {
    width: 100%;
  }
`;

export const SGridRow2 = styled(Grid)<{ $isExpanded: boolean }>`
  && {
    width: 100%;
    display: ${({ $isExpanded }: { $isExpanded: boolean }) =>
      $isExpanded ? "flex" : "block"};
    flex-wrap: ${({ $isExpanded }: { $isExpanded: boolean }) =>
      $isExpanded ? "wrap" : "unset"};
    overflow-y: auto;
    gap: 10px;
  }
`;

export const SAssetsHeader = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textGray};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    padding: 0;
    margin: 0;
    margin-bottom: 8px;
    grid-column: 2;
  }
`;

export const SAssetsList = styled(List)`
  && {
    max-height: 600px;
    overflow-y: auto;
  }
`;

interface SCategorySeparatorLineType {
  $barColor: string;
}

export const SCategorySeparatorLine = styled(
  Typography
)<SCategorySeparatorLineType>`
  && {
    width: 100%;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    border-bottom: 1px solid ${({ $barColor }) => $barColor};
    margin-bottom: 6px;
    color: ${({ $barColor }) => $barColor};
  }
`;

export const SCloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: none;
  background-color: ${({ theme }) => theme.colors.textGray};
  color: ${({ theme }) => theme.colors.textWhite};
  font-size: 20px;
  line-height: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &:hover {
    background-color: ${({ theme }) => theme.colors.textDark};
  }

  @media (max-width: 768px) {
    top: 12px;
    right: 12px;
    width: 20px;
    height: 20px;
    font-size: 16px;
  }
`;
