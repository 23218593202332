import React from "react";
// import Big from "big.js";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// REMOVE COMMENTS BELOW IF PORTFOLIO DIALOG IS REQUIRED AGAIN
// import { PortfolioDialog } from "../PortfolioDialog/PortfolioDialog";
// import { calculateAssetsTotalPercentage } from "../../../../utils";
import { useCurrentPortfolio, usePortfolioEditor } from "../../../../store";
import Analytics from "../../../../analytics";
import { SGrid, SButton } from "./styles";

const analytics = Analytics.getInstance();

export const Footer: React.FC<{
  onAnalysisClick?: () => void;
  isModal?: boolean;
}> = ({ onAnalysisClick, isModal }) => {
  Footer.defaultProps = {
    onAnalysisClick: () => "",
    isModal: false,
  };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedPortfolio } = useCurrentPortfolio();
  const { isExpanded } = usePortfolioEditor();

  // REMOVE COMMENTS BELOW IF PORTFOLIO DIALOG IS REQUIRED AGAIN
  // const [open, setOpen] = useState(false);

  // const assetsTotalPercentage = calculateAssetsTotalPercentage(
  //   selectedPortfolio?.assets || null
  // );

  // const handleClick = (): void => {
  //   if (
  //     Big(assetsTotalPercentage).gt(100) ||
  //     Big(assetsTotalPercentage).lt(100)
  //   ) {
  //     setOpen((state) => !state);
  //   } else {
  //     navigate("../analysis");
  //   }
  // };

  // const handleClose = (): void => {
  //   setOpen(false);
  // };

  const trackPageview = (): void => {
    try {
      analytics.trackPageview({
        name: "analysis_resultado",
        properties: {
          portfolio: selectedPortfolio,
        },
      });
    } catch (err) {
      // Do nothing
      // TODO: Add Sentry
    }
  };

  const handleAnalysisClick = (): void => {
    if (isModal) {
      if (onAnalysisClick) {
        onAnalysisClick();
      }
    } else {
      trackPageview();
      navigate("/analysis");
    }
  };

  const isSuperCarteira = process.env.REACT_APP_THEME === "SUPER_CARTEIRA";

  return location.pathname !== "/analysis" || isModal ? (
    <SGrid
      $isExpanded={isExpanded}
      $isSupercarteira={isSuperCarteira}
      $isModal={isModal || false}
    >
      <SButton
        endIcon={
          <ArrowForwardIosIcon style={{ fontSize: 14, marginLeft: 8 }} />
        }
        onClick={handleAnalysisClick}
      >
        {t("portfolioEditorAnalysis")}
      </SButton>
    </SGrid>
  ) : null;
};
